import React, {useState, useEffect} from 'react'
import Lawyerfooter from '../component/Lawyerfooter'
import Lawyersidebar from '../component/Lawyersidebar'
import SweetAlert from 'react-bootstrap-sweetalert'
import axios from 'axios'
import Cookies from 'universal-cookie'
import {Link, useParams, useHistory} from 'react-router-dom'
import { Instagram, Facebook } from 'react-content-loader'

function Lawyerdashboard() {
    const cookies = new Cookies();
    var lawyertocken = cookies.get('LAWYER');
    let history = useHistory()
    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const [packageList, setPackageList] = useState([]);
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }
    const [Alert, setAlert] = useState(null);
    const [payment, setPayment] = useState([]);
    const [Package, setPackage] = useState([]);
    const [PackageId, setPackageId] = useState([]);
    const [lawyerId, setLawyerId] = useState(null);
    const [dashboardText, getDashboardText] = useState(null);
    const [isLoader, setLoader] = useState(true)

    let {thanks} = useParams();

    const getBadgeContent = async () => {
        await axios({
            method: "get",
            url: `${REACT_APP_LIVE_BACKEND_BASE_URL}api/lawyer/profile-content/1`,
            headers: {"Content-Type": "application/json"}
        }).then((response) => {
            const data = response.data;
            getDashboardText(data.data)
        }).catch((error) => {
            const errors = error.data.errors;
        })
    }

    useEffect(() => {
        document.title = 'Lawyer Dashboard';
        if (thanks === 'thanks') {
            setAlert (
                <SweetAlert success title="Success" onConfirm={() => {
                    setAlert(null)
                    history.push('./../../dashboard/')
                }}>
                    {'Thank you ! Package Successfully purchased.'} 
                </SweetAlert>
            )
        } else if (thanks === 'fails') {
            setAlert (
                <SweetAlert danger  title="Fails" onConfirm={() => {
                    setAlert(null)
                    history.push('./../../dashboard/')
                }}>
                    {'Error ! Package Not purchased.'}
                </SweetAlert>
            )
        }else if(thanks === 'cancel'){
            setAlert (
                <SweetAlert  danger title="Ooops!" onConfirm={() => {
                    setAlert(null)
                    history.push('./../../dashboard/')
                }}>
                    {'Payment Cancelled.'}
                </SweetAlert>
            )
        }
    }, []);

    useEffect(() => {
        const fetchDataAsync = async () => {
            try {
                await Promise.all([
                    fetchPackageList(),
                    LawyerPackage(),
                    getBadgeContent()
                ]);

                setLoader(false);
            } catch (error) {
                console.error("An error occurred:", error);
                setLoader(false);
            }
        }

        fetchDataAsync()
    }, [])

    const fetchPackageList = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL + "api/get-lawyer-package-list").then((res) => res.json()).then((response) => {
            setPackageList(response.packagelist);
        })
    }

    const LawyerPackage = () => {
        fetch(REACT_APP_LIVE_BACKEND_BASE_URL + "api/get-lawyer-package-detail/" + lawyertocken).then((res) => res.json()).then((response) => {
            setPayment(response.paymentdetail);
            setPackage(response.lawyerpackage);
            setPackageId(response.packageid);
            setLawyerId(response.lawyerid);
        })
    }

    const BuyPackage = async (e, subscription) => {
        var packageid = e.target.getAttribute("packageid");
        if (packageid !== null) {
            var url = REACT_APP_LIVE_BACKEND_BASE_URL + "api/subscription-payment";
            if (subscription < 1) {
                url = REACT_APP_LIVE_BACKEND_BASE_URL + "api/buy-package";
            }

            try {
                await axios({
                    method: "post",
                    url: url,
                    data: {
                        packageid: packageid,
                        lawyerid: lawyerId
                    },
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }).then((response) => {
                    const errors = response.errors
                    if (response.status) {
                        window.location.href = response.data.redirecturl;
                    } else {
                        DvInnerHTML('.text-danger', '');
                        if (parseInt(Object.keys(errors).length) > 0) {
                            for (var key in errors) {
                                if (errors.hasOwnProperty(key)) {
                                    if (document.getElementById(key + '-error') === null) {} else {
                                        document.getElementById(key + '-error').innerHTML = errors[key];
                                    }
                                }
                            }
                        } else {
                            setAlert((
                                <SweetAlert danger title="" onConfirm={() => { setAlert(null)}}>{response.msg}</SweetAlert>
                            ))
                        }
                    }
                });
            } catch (response) {
                console.log(response)
            }
        }
    }

    return (
        <div className="wrapper d-flex align-items-stretch">
            <Lawyersidebar/>
            <div id="content" className="main-content">
                <div className="bg-custom">
                    <div className="guest-posts">
                        <div className="inner-guest-posts c-spacing-s">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="posts-title">
                                        <span>Lawyer Dashboard</span>
                                    </div>
                                </div>
                            </div>

                            <div className="inner-account-content px-3 pb-4">
                                <div className="row">
                                    <div className="col-md-6">
                                        {
                                            isLoader ? <Instagram /> : (
                                                <div className="left-account-content-box">
                                                    <div className="row">
                                                        {packageList !== null ? packageList.map((item, i) => {
                                                            return (<div className={item.subscription === 1 ? "col-md-12": "col-md-6"} key={`package-list-${i}`}>
                                                                <div className="card mb-4">
                                                                    <div className={item.subscription === 1 ? "card-body": "card-body text-center"}>
                                                                        <h5 className={item.subscription === 1 ? "primary-title": "card-title"}>
                                                                            {item.title} {item.subscription === 1 ? (<span className='float-end'>${item.price}</span>) : ""}
                                                                        </h5>
                                                                        {item.subscription !== 1 && item.paid_type === 1 ? (<h5>${item.price}</h5>) : ""}
                                                                        <p className={item.subscription === 1 ? "primary-text": "card-text"}>{item.description}</p>
                                                                        {item.subscription === 1 ? (<>
                                                                            <p className='secondary-text'>Going live on ServeTheInjured.com profile lets you…</p>
                                                                            <ul>
                                                                                <li>Set yourself apart from your competition to attract new leads</li>
                                                                                <li>Get some powerful backlinks to your injury law firm website</li>
                                                                                <li>Update your profile with as much information as you want, as often as you want</li>
                                                                                <li>Publish articles to improve credibility and authority online</li>
                                                                            </ul>
                                                                            </>
                                                                        ) : "" }
                                                
                                                                        {item.paid_type === 1 && !item.url ? (
                                                                            <div className="buy-btn">
                                                                                {PackageId !== null ? (
                                                                                    PackageId.includes(item.id) ? <button packageid={item.id} className="package-button">Purchased</button> : <button onClick={(e) => BuyPackage(e, item.subscription)} packageid={item.id} className="package-button">{item.button_text}</button>
                                                                                ) : <button onClick={(e) => BuyPackage(e, item.subscription)} packageid={item.id}className="package-button">Buy</button>} 
                                                                            </div>
                                                                        ) : (
                                                                            <div className="buy-btn">
                                                                                {
                                                                                    item.url_target === '_self' ? (<Link to={item.url} className="package-button">{item.button_text}</Link>) : (<a href={item.url}>{item.button_text}</a>)
                                                                                }
                                                                            </div>
                                                                        )
                                                                    } </div>
                                                                </div>
                                                            </div>)
                                                        }) : 'No Package'}
                                                    </div>
                                                </div>
                                            )
                                        }
                                        
                                    </div>

                                    <div className="col-md-6">
                                        {
                                            isLoader ? <Facebook /> : dashboardText ? (<div className="right-link-sec">
                                                {
                                                    dashboardText.youtube_video !== undefined ? (<iframe width="100%" height="315" src={dashboardText.youtube_video} title={dashboardText.title !== undefined ? dashboardText.title : "YouTube video player"} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>) : ("")
                                                }

                                                {
                                                    dashboardText.title !== undefined ? (<h3>{dashboardText.title}</h3>) : ("")
                                                }

                                                {
                                                    dashboardText.discription !== undefined ? (<p>{dashboardText.discription}</p>) : ("")
                                                }

                                                {
                                                    dashboardText.content !== undefined ? (<div dangerouslySetInnerHTML={{ __html: dashboardText?.content }} />) : ("")
                                                }
                                            </div>) : ("")
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="inner-guest-posts">
                            <div className="inner-profile-sec">
                                <div className="heading-title c-text-small">
                                    <p>My Packages</p>
                                </div>
                                { Package !== null ? (
                                    <div className="data-table-sec table-responsive">
                                        <table id="example" className="display" cellSpacing="0" width="100%">
                                            <thead>
                                                <tr>
                                                    <th>Title</th>
                                                    <th>Description</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody> 
                                                { Package !== null ? Package.map((item, i) => {
                                                    return (<tr key={`tr${i}`}>
                                                            <td>{item.title}</td>
                                                            <td>{item.description}</td>
                                                            <td>{item.price ? `$${item.price}` : 'N/A'}</td>
                                                        </tr>)
                                                }) : ''}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : '<p>No package found.</p>'} 
                            </div>
                        </div>
                    </div>
                </div>
                {Alert}
                <Lawyerfooter/>
            </div>
        </div>
    )

}

export default Lawyerdashboard
