import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ContentLoader from 'react-content-loader'
import ScrollToTop from './ScrollToTop'
import LowercaseRedirect from './LowercaseRedirect'

function Header({isLogin, loginType}){
    const [headerMenu, setHeaderMenu] = useState([])
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
    const [isMenuLoader, setIsMenuLoader] = useState(true)
    const [isShowMenu, setIsShowMenu] = useState(false)

    const MenuLoader = () => (
        <ContentLoader speed={3} width={400} height={48} viewBox="0 0 476 124" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
            <rect x="48" y="10" rx="3" ry="3" width="400" height="20" /> 
            <rect x="48" y="40" rx="3" ry="3" width="380" height="20" /> 
            <rect x="48" y="70" rx="3" ry="3" width="380" height="20" /> 
        </ContentLoader>
    );

    useEffect(() => {
        const MENUURL = process.env.REACT_APP_WORDPRESS_API_URL+'/menu-locations/primary_menu'
        const fetchHeaderMenu = () => {
            fetch(MENUURL).then((res) => res.json()).then((response) => {
                setHeaderMenu(response.menus)
                setIsMenuLoader(false);
            })
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768)
        }
        
        fetchHeaderMenu()
        window.addEventListener('resize', handleResize())
        return () => {
            window.removeEventListener('resize', handleResize())
        }
    }, [])

    return (
        <header className="header">
            <LowercaseRedirect />
            <ScrollToTop />
            <nav className="navbar navbar-expand-lg navbar-light bg-white">
                <div className="container">
                    <div className="logo">
                        <a className="navbar-brand" href={`${process.env.REACT_APP_BASE_URL}/`}>
                            <img src={`${process.env.REACT_APP_LIVE_BASE_URL}images/servertheinjured-logo.webp`} height="38" width="260" style={{maxWidth:'100%', height:'auto'}} alt="Server The Injured" />
                        </a>
                    </div>

                    <button className="navbar-toggler" aria-label='menu button' type="button" onClick={() => setIsShowMenu(!isShowMenu)}>
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    {
                        isMenuLoader ? (isMobile ? "" : <MenuLoader />): 
                        (
                            <div className={isShowMenu ? `collapse navbar-collapse navbar-collapse-menu show` : `collapse navbar-collapse navbar-collapse-menu`} id="navbarSupportedContent">
                                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                                    {
                                        headerMenu.length >= 1 ? headerMenu.map((menu, index) => {
                                            return (
                                                menu.children.length >= 1 ?
                                                (<li className='nav-item dropdown' key={`menu-${index}`}>
                                                    <DropdownButton title={menu.title} className="nav-dropdown-links">
                                                    { 
                                                        menu.children.map((submenu, subindex) => {
                                                            return (<a className='dropdown-item' href={submenu.url} key={`menu-${subindex}`}>{submenu.title}</a>)
                                                        })
                                                    } 
                                                    </DropdownButton>
                                                </li>) : (
                                                    <li className="nav-item" key={`menu-${index}`}>
                                                        <a href={menu.url} className="nav-link active">{menu.title}</a>
                                                    </li>
                                                )
                                            )
                                        }): '' 
                                    }                          
                                </ul>
                                <div className="mobile-head-top-btn">
                                    {
                                        isLogin ? (loginType === "Lawyer" ? <a href="/dashboard/" rel="nofollow">Dashboard</a> : <a href="/admin-dashboard/" rel="nofollow">Dashboard</a>) : (<>
                                            <Link to="/sign-in/" rel="nofollow">Attorney login</Link>
                                            <Link to="/signup/" rel="nofollow">Get listed</Link>
                                        </>)
                                    }
                                </div>
                            </div>
                        )
                    }
                    
                    <div className="head-top-btn">
                        {isLogin ? (loginType === "Lawyer" ? <a href="/dashboard/" rel="nofollow">Dashboard</a> : <a href="/admin-dashboard/" rel="nofollow">Dashboard</a>) : (<><Link to="/sign-in/" rel="nofollow">Attorney login</Link><Link to="/signup/" rel="nofollow">Get listed</Link></>)}
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header