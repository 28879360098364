import React, {useEffect, useState} from 'react'
import Lawyerfooter from '../component/Lawyerfooter'
import Lawyersidebar from '../component/Lawyersidebar'
import $ from 'jquery'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import SweetAlert from 'react-bootstrap-sweetalert'
import Cookies from 'universal-cookie'
import { LawLicense, LawAward, ProfessionalExperience, ProfessionalAssociation, LawyerEducation, FurtherReading, SpeakingEngagement, NotableSettlement, LawyerAttorney, LawyerSocials, PhotoVideoLink } from "../component/LawyerDashboardForm"
import { Facebook } from 'react-content-loader'


function Lawyerdashboardprofile() {

    const cookies = new Cookies();
    var lawyertocken = cookies.get('LAWYER');

    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const [lawyerdata, setLawyerdata] = useState({
        biography: "",
        city: "",
        created_at: "",
        email: "",
        firm_description: "",
        firmname: "",
        id: 0,
        location_url: "",
        map_url: "",
        mobile: "",
        name: "",
        office_phone: "",
        practice_area: "",
        profile_photo: "",
        state: "",
        street_address: "",
        updated_at: "",
        website: "",
        zipcode: "",
        show_ad_state_id: "",
        show_ad_city_id: "",
        cities:{
            id: "",
            name: ""
        },
        states:{
            id: "",
            name: ""
        }
    });

    let {id} = useParams();

    if (id) {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: 'smooth'});
        }
    }

    const [data, getData] = useState([])
    const [Alert, setAlert] = useState(null);
    const [state, setState] = useState([]);
    const [showAdStateId, setShowAdStateId] = useState(null);
    const [city, setCity] = useState([]);    
    const [isPaid, setIsPaid] = useState(false);
    const [isLoader, setLoader] = useState(true);
    const [lawyerOffice, setLawyerOffice] = useState(null);
    const [lawyerProfileText, setLawyerProfileText] = useState(null);
    const StateURL = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/get-all-states-list';
    const CityUrl = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/get-all-city-list';
    const DvInnerHTML = function (target, newText) {
        document.querySelectorAll(target).forEach(function (element) {
            element.textContent = newText;
        });
    }

    const [editGeneralProfile, setEditGeneralProfile] = useState(false)
    const [buttonSubmit, setButtonSubmit] = useState(false)
    const options = [];
    for (let i = 1; i <= 100; i++) {
        options.push(i);
    }

    const getProfileContent = async () => {
        await axios({
            method: "get",
            url: `${REACT_APP_LIVE_BACKEND_BASE_URL}api/lawyer/profile-content/2`,
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            const data = response.data;
            setLawyerProfileText(data.data)
        }).catch((error) => {
            const errors = error.data.errors;
        });
    }

    useEffect(() => {
        document.title = 'Dashboard Profile';
        const fetchDataAsync = async () => {
            try {
                await Promise.all([
                    fetchData(),
                    fetchlawyerdata(),
                    fetchstateData(),
                    getProfileContent(),
                ]);

                setLoader(false);
            } catch (error) {
                console.error("An error occurred:", error);
                setLoader(false);
            }
        }
        fetchDataAsync();
    }, []);
    

    const URL = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/get-all-practise-area';
    const LAWYERURL = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/get-lawyer-profile-data/' + lawyertocken;
    const fetchData = () => {
        fetch(URL).then((res) => res.json()).then((response) => {
            getData(response)
        })
    }

    const fetchlawyerdata = () => {
        fetch(LAWYERURL).then((res) => res.json()).then((response) => {
            if (response.lawyerprofile !== '') {
                setLawyerdata(response.lawyerprofile);
                setShowAdStateId(response.lawyerprofile.show_ad_state_id)
                if(response.lawyerprofile.lawyer_other_office){
                    setLawyerOffice(response.lawyerprofile.lawyer_other_office)
                }
                
                if (response.lawyerprofile.subscription_status === "Active") {
                    setIsPaid(true)
                }

                if (response.lawyerprofile.state !== null) {
                    fetch(CityUrl + '/' + response.lawyerprofile.state).then((res) => res.json()).then((response) => {
                        setCity(response)
                    })
                }
            }
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        $("#start-spin-lawyer").html('<i class="fas fa-spinner fa-spin"></i>');
        $("#start-spin-lawyer").prop('disabled', true);

        try {
            await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL + "api/add-lawyer-profile",
                data: new FormData(e.target),
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                $("#start-spin-lawyer").html('Save');
                $("#start-spin-lawyer").prop('disabled', false);
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                    setAlert((
                        <SweetAlert success title="" onConfirm={() => { setAlert(null); window.location.reload(); }} >
                            {response.data.msg}
                        </SweetAlert>
                    ))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                const keys = key.split(".");
                                let errorKey = keys[0];
                                if(typeof keys[1] !== "undefined"){
                                    errorKey = errorKey+"-"+keys[1];
                                }
                                
                                if (document.getElementById(errorKey + '-error') !== null) {
                                    document.getElementById(errorKey + '-error').innerHTML = errors[key];
                                } 
                            }
                        }
                    } else {
                        setAlert((
                            <SweetAlert danger title="" onConfirm={() => {setAlert(null)}}>
                                {response.data.msg}
                            </SweetAlert>
                        ))
                    }
                }
            });
        } catch (response) {
            $("#start-spin-lawyer").html('Save')
            $("#start-spin-lawyer").prop('disabled', false)
            setAlert((
                <SweetAlert danger title="" onConfirm={() => {setAlert(null)}}>
                    {response.message}
                </SweetAlert>
            ))
        }

    }

    const handleUpdateSlogan = async (e) => {
        e.preventDefault()
        const form = e.target
        const submitButton = form.querySelector('button[type="submit"]')
        submitButton.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'
        submitButton.disabled = true;

        await axios({
            method: "post",
            url: REACT_APP_LIVE_BACKEND_BASE_URL + "api/lawyer-update-slogan",
            data: new FormData(e.target),
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            DvInnerHTML('.text-danger', '');
            submitButton.innerHTML = 'Submit';
            submitButton.disabled = false;
            setAlert((
                <SweetAlert success title="Success" onConfirm={() => { setAlert(null) }}>
                    {response.data.message}
                </SweetAlert>
            ));
        }).catch((error) => {
            DvInnerHTML('.text-danger', '');
            submitButton.innerHTML = 'Submit'
            submitButton.disabled = false
            const errors = error.response.data
            if(errors.message === undefined){
                Object.entries(errors.errors).forEach(([key, value]) => {
                    const elementId = `${key}_error`;
                    document.getElementById(elementId).innerHTML = value;
                });
            }else{
                setAlert((
                    <SweetAlert danger title="Oops!" onConfirm={() => {setAlert(null)}}>
                        {errors.message}
                    </SweetAlert>
                ))
            }
        })
    }

    const handleUpdateBussinessYear = async (e) => {
        e.preventDefault();
        const form = e.target;
        const submitButton = form.querySelector('button[type="submit"]');
        submitButton.innerHTML = '<i class="fas fa-spinner fa-spin"></i>';
        submitButton.disabled = true;

        await axios({
            method: "post",
            url: REACT_APP_LIVE_BACKEND_BASE_URL + "api/lawyer-update-business-year",
            data: new FormData(e.target),
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            DvInnerHTML('.text-danger', '');
            submitButton.innerHTML = 'Submit';
            submitButton.disabled = false;
            setAlert((
                <SweetAlert success title="Success" onConfirm={() => { setAlert(null) } }>
                    {response.data.message}
                </SweetAlert>
            ));
        }).catch((error) => {
            DvInnerHTML('.text-danger', '');
            submitButton.innerHTML = 'Submit';
            submitButton.disabled = false;
            const errors = error.response.data;

            if(errors.message === undefined){
                Object.entries(errors.errors).forEach(([key, value]) => {
                    const elementId = `${key}_error`;
                    document.getElementById(elementId).innerHTML = value;
                });
            }else{
                setAlert((
                    <SweetAlert danger title="Oops!" onConfirm={() => { setAlert(null) }}>
                        {errors.message}
                    </SweetAlert>
                ))
            }
        });

    }

    const PracticeAreaSubmit = async (e) => {
        e.preventDefault();
        try { // make axios post request
            const response = await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL + "api/add-lawyer-profile-practicearea",
                data: new FormData(e.target),
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                    setAlert((
                        <SweetAlert success title="" onConfirm={() => { setAlert(null) }}>{ response.data.msg }</SweetAlert>
                    ))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {} else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((
                            <SweetAlert danger title="" onConfirm={() => { setAlert(null) }}>
                                {response.data.msg}
                            </SweetAlert>
                        ))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    const MapurlSubmit = async (e) => {
        e.preventDefault();
        try { // make axios post request
            await axios({
                method: "post",
                url: REACT_APP_LIVE_BACKEND_BASE_URL + "api/add-lawyer-profile-mapurl",
                data: new FormData(e.target),
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                const errors = response.data.errors;
                if (response.data.status) {
                    DvInnerHTML('.text-danger', '');
                    setAlert((
                        <SweetAlert success title=""
                            onConfirm={
                                () => {
                                    setAlert(null)
                                }
                        }>
                            {
                            response.data.msg
                        }</SweetAlert>
                    ))
                } else {
                    DvInnerHTML('.text-danger', '');
                    if (parseInt(Object.keys(errors).length) > 0) {
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                if (document.getElementById(key + '-error') === null) {} else {
                                    document.getElementById(key + '-error').innerHTML = errors[key];
                                }
                            }
                        }
                    } else {
                        setAlert((
                            <SweetAlert danger title=""
                                onConfirm={
                                    () => {
                                        setAlert(null)
                                    }
                            }>
                                {
                                response.data.msg
                            }</SweetAlert>
                        ))
                    }
                }
            });
        } catch (response) {
            console.log(response)
        }
    }

    const fetchstateData = () => {
        fetch(StateURL).then((res) => res.json()).then((response) => {
            setState(response);
        })
    }

    const fetchCitiesData = (state, city, targetElement) => {
        fetch(CityUrl + '/' + state).then((res) => res.json()).then((response) => {
            var html = "";
            Object.entries(response).forEach(([key, item]) => {
                if(item.id === city){
                    html += "<option selected value='"+item.id+"'>"+item.name+"</option>";
                }else{
                    html += "<option value='"+item.id+"'>"+item.name+"</option>";
                }
            });
            document.getElementById(targetElement).innerHTML = html;
        })
    }

    const stateChange = (event) => {
        if (event.target.value !== '') {
            fetch(CityUrl + '/' + event.target.value).then((res) => res.json()).then((response) => {
                setCity(response);
            })
        }
    }

    const submitLawFirmDescription = async (event) => {
        event.preventDefault();
        axios({
            method: "POST",
            url: REACT_APP_LIVE_BACKEND_BASE_URL + "api/lawyer/update-law-firm-description",
            data: new FormData(event.target)
        }).then((response) => {
            const message = response.data.message;
            DvInnerHTML('.text-danger', '');
            setAlert((
                <SweetAlert success title=""
                    onConfirm={
                        () => {
                            setAlert(null)
                        }
                }>
                    {message}
                </SweetAlert>
            ))
        }).catch((error) => {
            const errors = error.response.data;

            if(errors.message === undefined){
                Object.entries(errors.errors).forEach(([key, value]) => {
                    const elementId = key+"-error";
                    document.getElementById(elementId).innerHTML = value;
                });
            }else{
                setAlert((
                    <SweetAlert danger title="Oops!"
                        onConfirm={
                            () => {
                                setAlert(null)
                            }
                    }>
                        {
                        errors.message
                    }</SweetAlert>
                ))
            }
        });
    }

    const handleAdLocationSubmit = async (e) => {
        e.preventDefault();
        axios({
            method: "POST",
            url: REACT_APP_LIVE_BACKEND_BASE_URL + "api/lawyer/update-ad-location",
            data: new FormData(e.target)
        }).then((response) => {
            const message = response.data.message;
            DvInnerHTML('.text-danger', '');
            setAlert((
                <SweetAlert success title=""
                    onConfirm={
                        () => {
                            setAlert(null)
                            window.location.reload();
                        }
                }>
                    {message}
                </SweetAlert>
            ))
        }).catch((error) => {
            const errors = error.response.data;

            if(errors.message === undefined){
                Object.entries(errors.errors).forEach(([key, value]) => {
                    const elementId = key+"-error";
                    document.getElementById(elementId).innerHTML = value;
                });
            }else{
                setAlert((
                    <SweetAlert danger title="Oops!"
                        onConfirm={
                            () => {
                                setAlert(null)
                            }
                    }>
                        {
                        errors.message
                    }</SweetAlert>
                ))
            }
        });
    }

    $(document).on('change', "#profile_photo", function () {
        if (typeof(FileReader) != "undefined") {

            var image_holder = $(this).attr('previewimage');

            var reader = new FileReader();
            reader.onload = function (e) {
                $("#" + image_holder).prop('src', e.target.result)
            }
            reader.readAsDataURL($(this)[0].files[0]);
        } else {
            alert("This browser does not support FileReader.");
        }
    });

    $(document).on('click', '#delete-photos', function () {
        var deleteattr = $(this).attr('deleteprev');
        $("." + deleteattr).val('');
        $("." + deleteattr).prop('src', '');
    });

    $(document).on('change', '.states', function () {
        const state = $(this).val();
        const parentClass = $(this).closest('.row');
        const targetElement =  parentClass.find(".cities");
        let html = "<option value=''>Select City</option>";
        if(state !== ""){
            fetch(CityUrl + '/' + state).then((res) => res.json()).then((response) => {
                Object.entries(response).forEach(([key, item]) => {
                    html += "<option value='"+item.id+"'>"+item.name+"</option>";
                })
                targetElement.html(html);
            });
        }
        targetElement.html(html);
    });

    return (
        <div className="wrapper d-flex align-items-stretch">
            <Lawyersidebar/>
            <div id="content" className="main-content">
                <div className="main-profile-sec">
                    <div className='row'>
                        <div className='col-md-8'>
                            <div className="profile-sec">
                                <form onSubmit={(e) => handleSubmit(e)}>
                                    <div className="inner-guest-posts">

                                        <div className="inner-profile-sec" id="lawyer_personal_info">
                                            <div className="heading-title c-text-small">
                                                <p>Edit Profile</p>
                                            </div>

                                            {
                                                isLoader ? <Facebook /> : (
                                                    <div className="profile-edit-sec">
                                                        <div className="row">
                                                            <input type="hidden" name="lawyer_id" id="lawyer_id" className="form-control" defaultValue={lawyerdata.id}/>
                                                            <input type="hidden" name="update_side" value="lawyer" />
                                                            
                                                            <div className="col-md-12">
                                                                <div className="profile-img-sec">
                                                                    <img data-url={lawyerdata.profile_photo} src={lawyerdata.profile_photo} id="lawyerprofileimage" className="delete_prev_image"/>
                                                                    {
                                                                        editGeneralProfile ? (
                                                                            <div className='action-button-profile'>
                                                                                <div className="upload-img-btn">
                                                                                    <input type="file" name="profile_photo" id="profile_photo" previewimage="lawyerprofileimage" className="delete_prev_image"/>
                                                                                    <span>Upload Profile Image</span>

                                                                                </div>
                                                                                <div className="delete-img-btn">
                                                                                    <button type="button" deleteprev="delete_prev_image" id="delete-photos">Delete Image</button>
                                                                                </div>
                                                                            </div>
                                                                        ) : ""
                                                                    }
                                                                </div>
                                                                <p className="text-danger" id="profile_photo-error"></p>
                                                            </div>

                                                            <div className="col-md-12">
                                                                <div className="form-group row mb-1">
                                                                    <span className='col-6 col-sm-3 col-md-3'>Name</span>
                                                                    <div className="col-6 col-sm-9 col-md-9">
                                                                        {
                                                                            editGeneralProfile ? (
                                                                                <>
                                                                                    <input type="text" name="name" id="name" className="form-control" defaultValue={
                                                                                    lawyerdata.name} />
                                                                                    <p className="text-danger" id="name-error"></p>
                                                                                </>
                                                                            ) : (
                                                                                <span>{lawyerdata.name}</span>
                                                                            )
                                                                        }                                                                    
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mb-1">
                                                                    <span className='col-6 col-sm-3 col-md-3'>Law Firm Name</span>
                                                                    <div className="col-6 col-sm-9 col-md-9">
                                                                        {
                                                                            editGeneralProfile ? (
                                                                                <>
                                                                                    <input type="text" name="firmname" id="firmname" defaultValue={ lawyerdata.firmname } className="form-control"/>
                                                                                    <p className="text-danger" id="firmname-error"></p>
                                                                                </>
                                                                            ) : (
                                                                                <span>{lawyerdata.firmname}</span>
                                                                            )
                                                                        }                                                                    
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mb-1">
                                                                    <span className='col-6 col-sm-3 col-md-3'>Street Address</span>
                                                                    <div className="col-6 col-sm-9 col-md-9">
                                                                        {
                                                                            editGeneralProfile ? (
                                                                                <>
                                                                                    <input type="text" name="street_address" id="street_address" defaultValue={ lawyerdata.street_address } className="form-control"/>
                                                                                    <p className="text-danger" id="street_address-error"></p>
                                                                                </>
                                                                            ) : (
                                                                                <span>{lawyerdata.street_address}</span>
                                                                            )
                                                                        }                                                                    
                                                                    </div>
                                                                </div>
                                                                <div className='row mb-1'>
                                                                    <div className='col-md-4'>
                                                                        <div className="form-group row mb-2">
                                                                            <span className='col-6 col-sm-3 col-md-3'>State</span>
                                                                            <div className="col-6 col-sm-9 col-md-9">
                                                                                {
                                                                                    editGeneralProfile ? (
                                                                                        <>
                                                                                        <select name="state" id="state" onChange={(edit) => stateChange(edit)} className="form-control" defaultValue={lawyerdata.state}>
                                                                                            <option value="">Select State</option>
                                                                                            {
                                                                                            state.map((item, i) => {
                                                                                                return (<option value={ item.id } key={i}> { item.name }</option>)
                                                                                            })
                                                                                            }
                                                                                        </select>
                                                                                        <p className="text-danger" id="state-error"></p>
                                                                                        </>
                                                                                    ) : (
                                                                                        <span>{ lawyerdata.states ? lawyerdata.states.name : ""}</span>
                                                                                    )
                                                                                }                                                               
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-md-4'>
                                                                        <div className="form-group row mb-2">
                                                                            <span className='col-6 col-sm-3 col-md-3'>City</span>
                                                                            <div className="col-6 col-sm-9 col-md-9">
                                                                                {
                                                                                    editGeneralProfile ? (
                                                                                        <>
                                                                                        <select name="city" id="city" className="form-control" defaultValue={lawyerdata.city}>
                                                                                            <option value="">Select City</option>
                                                                                            {
                                                                                            city.map((item, i) => {
                                                                                                return (<option value={ item.id } key={i}> { item.name }</option>)
                                                                                            })
                                                                                            }
                                                                                        </select>
                                                                                        <p className="text-danger" id="city-error"></p>
                                                                                        </>
                                                                                    ) : (
                                                                                        <span>{lawyerdata.cities ? lawyerdata.cities.name: ""}</span>
                                                                                    )
                                                                                }                                                               
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-md-4'>
                                                                        <div className="form-group row mb-2">
                                                                            <span className='col-6 col-sm-5 col-md-5'>Zip Code</span>
                                                                            <div className="col-6 col-sm-7 col-md-7">
                                                                                {
                                                                                    editGeneralProfile ? (
                                                                                        <>
                                                                                            <input type="text" name="zipcode" id="zipcode" defaultValue={ lawyerdata.zipcode } className="form-control"/> <p className="text-danger" id="zipcode-error"></p>
                                                                                        </>
                                                                                    ) : (
                                                                                        <span>{lawyerdata.zipcode}</span>
                                                                                    )
                                                                                }                                                               
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mb-1">
                                                                    <span className='col-6 col-sm-3 col-md-3'>Phone Number</span>
                                                                    <div className="col-6 col-sm-9 col-md-9">
                                                                        {
                                                                            editGeneralProfile ? (
                                                                                <>
                                                                                    <input type="text" name="office_phone" id="office_phone" defaultValue={ lawyerdata.office_phone } className="form-control"/>
                                                                                    <p className="text-danger" id="office_phone-error"></p>
                                                                                </>
                                                                            ) : (
                                                                                <span>{lawyerdata.office_phone}</span>
                                                                            )
                                                                        }                                                                    
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mb-1">
                                                                    <span className='col-md-3'>Website</span>
                                                                    <div className="col-md-9">
                                                                        {
                                                                            editGeneralProfile ? (
                                                                                <>
                                                                                    <input type="text" name="website" id="website" defaultValue={ lawyerdata.website } className="form-control"/>
                                                                                    <p className="text-danger" id="website-error"></p>
                                                                                </>
                                                                            ) : (
                                                                                <span>{lawyerdata.website}</span>
                                                                            )
                                                                        }                                                                    
                                                                    </div>
                                                                </div>
                                                                <div className="form-group row mb-1">
                                                                    <span className='col-md-3'>Email</span>
                                                                    <div className="col-md-9">
                                                                        {
                                                                            editGeneralProfile ? (
                                                                                <>
                                                                                    <input type="text" name="email" id="email" defaultValue={ lawyerdata.email } className="form-control"/> <p className="text-danger" id="email-error"></p>
                                                                                </>
                                                                            ) : (
                                                                                <span className='email-info'>{lawyerdata.email}</span>
                                                                            )
                                                                        }                                                                    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                        <div className="save-btn text-right p-0">
                                                            {
                                                                editGeneralProfile ? (
                                                                    <button type={buttonSubmit ? "submit": "button"} onClick={() => setButtonSubmit(true)} className="mt-2" id="start-spin-lawyer">Save</button>
                                                                ) : (
                                                                    <button type="button" className="mt-2" onClick={() => setEditGeneralProfile(true)} >Edit</button>
                                                                )
                                                            } 
                                                        </div>
                                                    </div>
                                                )
                                            }                                            
                                        </div>
                                    </div>
                                </form>
                                
                                <div className='profile-sec' id="lawyer_firm_slogan">
                                    <div className="inner-guest-posts">
                                        <div className="inner-profile-sec">
                                            <div className="heading-title c-text-small">
                                                <p>Law Firm Slogan</p>
                                            </div>

                                            <form onSubmit={(e) => handleUpdateSlogan(e)}>
                                                <input type='hidden' name='lawyer_id' value={lawyerdata.id} />
                                                <div className="profile-edit-sec">
                                                    <p>Please add your law firms slogan in 55 characrters or less</p>
                                                    <div className='row'>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <input type="text" name="slogan" className="form-control" defaultValue={lawyerdata.slogan} />
                                                                <p className="text-danger" id="slogan_error"></p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="save-btn text-right p-0">
                                                        <button type="submit" className="mt-2">Save</button>
                                                    </div>
                                                </div>
                                            </form> 

                                        </div>
                                    </div>
                                </div>

                                <div className='profile-sec' id="lawyer_year_business">
                                    <div className="inner-guest-posts">
                                        <div className="inner-profile-sec">
                                            <div className="heading-title c-text-small">
                                                <p>Number Years in Business</p>
                                            </div>

                                            <form onSubmit={(e) => handleUpdateBussinessYear(e)}>
                                                <input type='hidden' name='lawyer_id' value={lawyerdata.id} />
                                                <div className="profile-edit-sec">
                                                    <p>Please tell us how many years your law firm has been in business</p>
                                                    <div className='row'>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <select name="year_in_business" className="form-control" defaultValue={lawyerdata.year_in_business} >
                                                                    <option value="" key={`year_in_business_0`}>--Select--</option>
                                                                    {options.map((val) => <option value={val} key={`year_in_business_${val}`} selected={ val === lawyerdata.year_in_business ? true : false}>{val}</option>)}
                                                                </select>
                                                                <p className="text-danger" id="year_in_business_error"></p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="save-btn text-right p-0">
                                                        <button type="submit" className="mt-2">Save</button>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                                
                                <div className="profile-sec" id="lawyer_location">
                                    <div className="inner-guest-posts">

                                        <div className="inner-profile-sec">
                                            <div className="heading-title c-text-small">
                                                <p>Choose Location</p>
                                            </div>
                                            <form onSubmit={(e) => handleAdLocationSubmit(e)}>
                                                <input type="hidden" name="id" className="form-control" defaultValue={lawyerdata.id} />
                                                <div className="profile-edit-sec">
                                                    <p className='px-2'>Please choose the location where you want your ad to appear.</p>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group height-80">
                                                                <label>State</label>
                                                                <select name="show_ad_state_id" id="show_ad_state_id" className="form-control states" defaultValue={showAdStateId}>
                                                                    <option value="">Select State</option>
                                                                    {
                                                                        state.map((item, i) => (<option value={ item.id } key={'location-state-option-'+i} selected={showAdStateId === item.id ? true : false}> { item.name }</option>))
                                                                    }
                                                                </select>
                                                                <p className="text-danger" id="show_ad_state_id-error"></p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group height-80">
                                                                <label>City</label>
                                                                <select name="show_ad_city_id" id="show_ad_city_id" className="form-control cities" defaultValue={lawyerdata.show_ad_city_id}>
                                                                    <option value="">Select City</option>
                                                                    {
                                                                        lawyerdata.show_ad_state_id ? fetchCitiesData(lawyerdata.show_ad_state_id, lawyerdata.show_ad_city_id, 'show_ad_city_id') :
                                                                        city.map((item, i) => (<option value={ item.id } key={'location-city-option-'+i}> { item.name }</option>)
                                                                        )
                                                                    }
                                                                </select>
                                                                <p className="text-danger" id="show_ad_city_id-error"></p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="save-btn text-right p-0">
                                                        <button type="submit" className="mt-2">Save</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <div className="profile-sec" id="lawyer_firm_description">
                                    <div className="inner-guest-posts">

                                        <div className="inner-profile-sec">
                                            <div className="heading-title c-text-small">
                                                <p>Law Firm Description</p>
                                            </div>
                                            <form onSubmit={(e) => submitLawFirmDescription(e)}>
                                                <input type='hidden' name='id' value={lawyerdata.id} />
                                                <div className="profile-edit-sec">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group height-80">
                                                                <textarea name="firm_description" defaultValue={ lawyerdata.firm_description } id="firm_description"></textarea>
                                                                <p className="text-danger" id="firm_description-error"></p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="save-btn text-right p-0">
                                                        <button type="submit" className="mt-2">Save</button>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <div className="profile-sec" id="lawyer_personal_practice">
                                    <div className="inner-guest-posts">

                                        <div className="inner-profile-sec">
                                            <div className="heading-title c-text-small">
                                                <p>Practice Areas</p>
                                            </div>
                                            <form onSubmit={PracticeAreaSubmit}>
                                                <input type="hidden" name="lawyer_id" id="lawyer_id" className="form-control" defaultValue={lawyerdata.id} />
                                                <div className="profile-edit-sec" id="">
                                                    <div className="row">
                                                        {data.map((item, i) => (
                                                            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3" key={i}>
                                                                <ul className="unstyled p-0"
                                                                    key={i}>
                                                                    {
                                                                    item.map((items, j) => (
                                                                        <li key={j}>
                                                                            <span className="form-check p-0">
                                                                                <label> {
                                                                                    lawyerdata.practice_area.includes(items.id) ? <input type="checkbox" name="practice_area[]" defaultChecked
                                                                                        value={
                                                                                            items.id
                                                                                        }/> : <input type="checkbox" name="practice_area[]"
                                                                                        value={
                                                                                            items.id
                                                                                        }/>
                                                                                }
                                                                                    {
                                                                                    items.name
                                                                                } </label>
                                                                            </span>
                                                                        </li>
                                                                    ))
                                                                } </ul>
                                                            </div>
                                                        ))} 
                                                    </div>
                                                    <p className="text-danger" id="practice_area-error"></p>
                                                    <div className="save-btn text-right p-0">
                                                        <button type="submit" className="mt-2">Save</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                
                                <LawLicense sates={state} />
                                <PhotoVideoLink />
                                <LawAward />
                                <ProfessionalExperience />
                                <ProfessionalAssociation />
                                <LawyerEducation />
                                <FurtherReading />
                                <SpeakingEngagement />
                                <NotableSettlement />
                                <LawyerAttorney />
                                <LawyerSocials />

                                <div className="profile-sec">
                                    <div className="inner-guest-posts">

                                        <div className="inner-profile-sec">
                                            <div className="heading-title c-text-small">
                                                <p>Google Map Location (<small>Add embed code</small>)</p>
                                            </div>
                                            <form onSubmit={MapurlSubmit}>
                                                <div className="profile-edit-section">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <input type="hidden" name="lawyer_id" value={ lawyerdata.id }/>
                                                                <textarea name="lawyer_map" rows="8" cols="120" id="lawyer_map" className="form-control" defaultValue={lawyerdata.map_url}></textarea>
                                                                <p className="text-danger" id="lawyer_map-error"></p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="save-btn text-right p-0">
                                                        <button type="submit" className="mt-2">Save</button>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='wrapper mb-3'>
                                <a href={`${process.env.REACT_APP_BASE_URL}/injury-lawyer-seo/consultation/`}>
                                    <img src={`${process.env.REACT_APP_LIVE_BASE_URL}images/banner-injury-law-seo-audit.webp`} style={{maxWidth:'100%', height:'auto'}} alt="" />
                                </a>
                            </div>
                            <div className='wrapper right-sidebar'>
                                <h3></h3>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
                {Alert}
                <Lawyerfooter/>
            </div>
        </div>
    )

}

export default Lawyerdashboardprofile
