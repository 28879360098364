import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'
import Lawyerfooter from '../component/Lawyerfooter'
import Lawyersidebar from '../component/Lawyersidebar'
import SweetAlert from 'react-bootstrap-sweetalert'
import Cookies from 'universal-cookie'
import { Instagram } from 'react-content-loader'

function Createbadge() {
    const cookies = new Cookies();
    const lawyertocken = cookies.get('LAWYER');
    const [badge, getBadge] = useState(null);
    const [badgeText, getBadgeText] = useState(null);
    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const URL = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/get-lawyer-badge/' + lawyertocken;
    const [Alert, setAlert] = useState(null);
    const [isLoader, setLoader] = useState(true)

    const getBadgeData = async () => {
        await axios({
            method: "get",
            url: URL,
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            const data = response.data;
            if (data.status) {
                getBadge(data.data)
            } else {
                setAlert((
                    <SweetAlert info title="Oops!" onConfirm={() => { setAlert(null) }}>
                        {data.message}
                    </SweetAlert>
                ))
            }
        }).catch((error) => {
            const errors = error.data.errors;
        });
    }

    const getBadgeContent = async () => {
        await axios({
            method: "get",
            url: `${REACT_APP_LIVE_BACKEND_BASE_URL}api/lawyer/profile-content/3`,
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            const data = response.data;
            getBadgeText(data.data)
        }).catch((error) => {
            const errors = error.data.errors;
        });
    }

    const getAllData = async () => {
        try {
            await Promise.all([
                getBadgeData(),
                getBadgeContent()
            ]);

            setLoader(false);
        } catch (error) {
            console.error("An error occurred:", error);
            setLoader(false);
        }
    }

    useEffect(() => {
        document.title = 'Badge'
        getAllData()
    }, []);

    return (        
        <div className="wrapper d-flex align-items-stretch">
            <Lawyersidebar/>
            <div id="content" className="main-content">
                <div className="bg-custom">
                    <div className="guest-posts">
                        <div className="top-award-sec">
                            <div className="container">
                                <div className="inner-top-award-sec">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-sm-12 col-md-8">
                                            <div className="top-award-title text-right">
                                                <h2>Congratulations on Your Award!</h2>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-3">
                                            <div className="top-award-img">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/SERVETHEINJURED.png`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isLoader ? <Instagram /> : (<div className="award-sec mt-4">
                            {badgeText ? (
                                <div className="award-content">
                                    <div className="award-description pb-4">
                                        {
                                            badgeText.youtube_video ? (<iframe className='mb-3' width="560" height="315" src={badgeText.youtube_video} title={badgeText.title !== undefined ? badgeText.title : "YouTube video player"} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>) : (<></>)
                                        }
                                        
                                        {
                                            badgeText.title !== undefined ? (<h3>{badgeText.title}</h3>) : (<></>)
                                        }

                                        {
                                            badgeText.discription !== undefined ? (<p>{badgeText.discription}</p>) : (<></>)
                                        }

                                        {
                                            badgeText.content !== undefined ? (<div dangerouslySetInnerHTML={{ __html: badgeText.content }} />) : (<></>)
                                        }
                                        
                                        
                                        {
                                        badge ? (

                                            <div className="badge-logo-sec">
                                                <div className="inner-badge-logo mb-5">
                                                    <div className="badge-title">
                                                        <h3>Get Your Badge</h3>
                                                    </div>

                                                    <div className="logo-sec">
                                                        <div className="left-preview-logo-img border-img">
                                                            <Link to="#">
                                                                <img src={
                                                                        badge.regular_image
                                                                    }
                                                                    alt={
                                                                        `${
                                                                            badge.title
                                                                        } regular`
                                                                    }/>
                                                            </Link>
                                                        </div>

                                                        <div className="right-logo-code">
                                                            <div className="logo-code-contemt">
                                                            &lt;a href="{badge.link_text}" style="display:inline-block; border:0;"&gt; &lt;img src="{ badge.regular_image }" alt="{badge.alt_text}" style="width:200px; display:block; height:auto" /&gt; &lt;/a&gt;
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ''}
                                    </div>
                                </div>
                            ): (<p>No Data Found.</p>)}
                        </div>)}
                    </div>
                </div>
                {Alert}
                <Lawyerfooter/>
            </div>
        </div>
    )

}

export default Createbadge
