import React, {useState, useEffect} from 'react';

function Footer() {

    const REACT_APP_LIVE_BACKEND_BASE_URL = process.env.REACT_APP_LIVE_BACKEND_BASE_URL;
    const LAWYERURL = process.env.REACT_APP_WORDPRESS_API_URL+'/site-data';
    const SOCIALURL = REACT_APP_LIVE_BACKEND_BASE_URL + 'api/get-front-socials';
    const FORTHEINJUREDURL = process.env.REACT_APP_WORDPRESS_API_URL + '/menu-locations/footer_menu_i';
    const POPULARLOCATIONURL = process.env.REACT_APP_WORDPRESS_API_URL + '/menu-locations/footer_menu_ii';
    const FORATTORNEYURL = process.env.REACT_APP_WORDPRESS_API_URL + '/menu-locations/footer_menu_iii';
    const FORCOMPANYURL = process.env.REACT_APP_WORDPRESS_API_URL + '/menu-locations/footer_menu_iv';
    const BOTTOMFOOTERURL = process.env.REACT_APP_WORDPRESS_API_URL + '/menu-locations/footer_menu_v';

    const [socialData, setSocialData] = useState([])
    const [popularLocation, setPopularLocation] = useState([])
    const [popularLocationTitle, setPopularLocationTitle] = useState(null)
    const [forTheInjured, setForTheInjured] = useState([])
    const [forTheInjuredTitle, setForTheInjuredTitle] = useState(null)
    const [forAttorney, setForAttorney] = useState([])
    const [forAttorneyTitle, setForAttorneyTitle] = useState(null)
    const [forCompany, setForCompany] = useState([])
    const [forCompanyTitle, setForCompanyTitle] = useState(null)
    const [bottomFooter, setBottomFooter] = useState([])
    const [contactInfo, setContactInfo] = useState([])
    const [copyright, setCopyright] = useState("<p> &copy; "+new Date().getFullYear()+" All rights reserved. </p>");

    const loadCopyrightData = () => {
        const url = REACT_APP_LIVE_BACKEND_BASE_URL + "api/copyright";
        fetch(url).then((res) => res.json()).then((response) => {
            setCopyright(response.data.copyright_text)
        })

    }

    const footerContactInfo = () => {
        fetch(LAWYERURL).then((res) => res.json()).then((response) => {
            setContactInfo(response)
            fetchlawyerdata()
        })
    }

    const fetchlawyerdata = () => {
        fetch(SOCIALURL).then((res) => res.json()).then((response) => {
            setSocialData(response.socialdata)
        })
    }

    const fetchForTheInjured = () => {
        fetch(FORTHEINJUREDURL).then((res) => res.json()).then((response) => {
            setForTheInjured(response.menus)
            setForTheInjuredTitle(response.title)
            fetchPopularLocation()
        })
    }

    const fetchPopularLocation = () => {
        fetch(POPULARLOCATIONURL).then((res) => res.json()).then((response) => {
            setPopularLocation(response.menus)
            setPopularLocationTitle(response.title)
            fetchForAttorney()
        })
    }    

    const fetchForAttorney = () => {
        fetch(FORATTORNEYURL).then((res) => res.json()).then((response) => {
            setForAttorney(response.menus)
            setForAttorneyTitle(response.title)
            fetchForCompany()
        })
    }

    const fetchForCompany = () => {
        fetch(FORCOMPANYURL).then((res) => res.json()).then((response) => {
            setForCompany(response.menus)
            setForCompanyTitle(response.title)
            fetchBottomFooter()
        })
    }

    const fetchBottomFooter = () => {
        fetch(BOTTOMFOOTERURL).then((res) => res.json()).then((response) => {
            setBottomFooter(response.menus)
            loadCopyrightData()
        })
    }

    useEffect(() => {
        setTimeout(() => {
            footerContactInfo()
            fetchForTheInjured()
        }, 200)
    }, [])

    return (
        <section className="footer">
            <div className="container">
                <div className="footer-content">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="footer-title">
                                <p className='h4'>{forTheInjuredTitle ? forTheInjuredTitle : ''}</p>
                            </div>
                            <ul>
                                {
                                    forTheInjured.length >= 1 ? forTheInjured.map((item, i) => {
                                        return (<li key={`for-the-injured-${i}`}><a href={item.url}>{item.title}</a></li>)
                                    }) : ''
                                }
                            </ul>

                            <div className="footer-title">
                                <p className='h4'>ServeTheInjured Inc.</p>
                            </div>
                            <p className="text-white mb-0">{contactInfo.address}</p>
                            <p className="text-white mb-0"><a href={`mailto:${contactInfo.email}`} rel="nofollow">{contactInfo.email}</a></p>
                            <p className="text-white"><a href={`tel:${contactInfo.phone}`} rel="nofollow">{contactInfo.phone}</a></p>
                        </div>

                        <div className="col-md-3">
                            <div className="footer-title">
                                <p className='h4'>{popularLocationTitle ? popularLocationTitle : ''}</p>
                            </div>
                            <ul> 
                                {
                                    popularLocation.length >= 1 ? popularLocation.map((item, i) => {
                                        return (<li key={`popular-locations-${i}`}><a href={item.url}>{item.title}</a></li>)
                                    }) : ''
                                }
                            </ul>
                        </div>

                        <div className="col-md-3">
                            <div className="footer-title">
                                <p className='h4'>{forAttorneyTitle ? forAttorneyTitle : ''}</p>
                            </div>
                            <ul>
                                {
                                    forAttorney.length >= 1 ? forAttorney.map((item, i) => {
                                        return (<li key={`for-attorneys-${i}`}><a href={item.url}>{item.title}</a></li>)
                                    }) : ''
                                } 
                            </ul>
                        </div>

                        <div className="col-md-3">
                            <div className="footer-title">
                                <p className='h4'>{forCompanyTitle ? forCompanyTitle : ''}</p>
                            </div>
                            <ul>
                                {
                                    forCompany.length >= 1 ? forCompany.map((item, i) => {
                                        return (<li key={`for-company-${i}`}><a href={item.url} rel="nofollow">{item.title}</a></li>)
                                    }) : ''
                                } 
                            </ul>

                            <div className="social-icons">
                                <div className="footer-title">
                                    <p className='h4'>Follow Us</p>
                                </div>
                                <ul>
                                    {
                                        socialData !== null ? socialData.map((item, i) => {
                                            return (
                                                <li key={i}>
                                                    <a href={item.link} dangerouslySetInnerHTML={{__html: item.icon}} title={item.title} rel ="me"></a>
                                                </li>
                                            )
                                        }) : ''
                                    } 
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="top-line"/>
                <div className="sub-footer">
                    <div className="copyright" dangerouslySetInnerHTML={{__html: copyright}}></div>
                    <div className="sub-footer-links">
                        <ul>
                            {
                                bottomFooter.length >= 1 ? bottomFooter.map((item, i) => {
                                    return (
                                        <li key={`sub-footer-${i}`}>
                                            <a href={item.url} rel="nofollow">{item.title}</a>
                                        </li>
                                    )
                                }) : ''
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer
